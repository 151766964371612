import {
    SET_PRODUCTS,
    SET_PRODUCTS_UPDATE,
} from "../actionTypes/productsActionTypes";

export const SetProductsState = (products: any[]) => {
    return {
        type: SET_PRODUCTS,
        payload: products,
    };
};

export const SetProductsUpdateState = (needsUpdate: boolean) => {
    return {
        type: SET_PRODUCTS_UPDATE,
        payload: needsUpdate,
    };
};
