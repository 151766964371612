import { combineReducers } from "@reduxjs/toolkit";
import { authReducer } from "./authReducer";
import { currentActionReducer } from "./currentActionReducer";
import { currentCategoryReducer } from "./currentCategoryReducer";
import { currentDeleteObjectReducer } from "./currentDeleteObjectReducer";
import { currentItemReducer } from "./currentItemReducer";
import { currentPageReducer } from "./currentPageReducer";
import { notificationsReducer } from "./notificationsReducer";
import { productsReducer } from "./productsReducer";
import { userReducer } from "./userReducer";
import { websocketReducer } from "./websocketReducer";
import { windowReducer } from "./windowReducer";

export const rootReducer = combineReducers({
    actionReducer: currentActionReducer,
    categoryReducer: currentCategoryReducer,
    itemReducer: currentItemReducer,
    notificationsReducer,
    deleteObjectReducer: currentDeleteObjectReducer,
    pageReducer: currentPageReducer,
    authReducer,
    websocketReducer,
    productsReducer,
    windowReducer,
    userReducer,
});
