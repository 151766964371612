import { SetProductsUpdateState } from "../redux/actions/productsActions";
import { SetWebsocketStatus } from "../redux/actions/websocketActions";
import store from "../redux/store";
import { getItem } from "./getItem";
import { websocketManager } from "./WebSocketManager";

export const connectToWebSocket = () => {
    websocketManager.onOpen(() => {
        store.dispatch(SetWebsocketStatus("connected"));
    });

    websocketManager.onClose(() => {
        store.dispatch(SetWebsocketStatus("disconnected"));
    });

    websocketManager.onError((err: any) => {
        store.dispatch(SetWebsocketStatus("error"));
        console.error("WS: ", err);
    });

    websocketManager.onMessage(async (event: any) => {
        const category = store.getState().categoryReducer.category;
        const updatedItemId = JSON.parse(event.data)?.changed_requisite_id;

        // Handle logic for "/" category
        if (category.link === "/") {
            handleRootCategory(updatedItemId);
        }
        // Handle logic for "/partners" category
        else if (category.link === "/partners") {
            handlePartnersCategory(updatedItemId);
        }
    });

    return websocketManager;
};

const handleRootCategory = async (updatedItemId: any) => {
    const products = store.getState().productsReducer.products;

    if (products && products.length > 0) {
        const isProductOnCurrentPage = products.some(
            (product: any) => product.id === updatedItemId
        );

        store.dispatch(SetProductsUpdateState(isProductOnCurrentPage));

        const currentEditItem = store.getState().itemReducer.item;
        const currentAction = store.getState().actionReducer.action;
        const isCurrentItemChanged = currentEditItem?.id === updatedItemId;

        if (
            currentEditItem &&
            currentAction !== "delete" &&
            isCurrentItemChanged
        ) {
            const category = store.getState().categoryReducer.category;

            if (category.getItemUrl) {
                await getItem(category.getItemUrl, {
                    id: updatedItemId,
                });
            }
        }
    }
};

const requestCache = new Set<string>();

let debounceTimeout: NodeJS.Timeout | null = null;

const debounce = (func: Function, delay: number) => {
    if (debounceTimeout) clearTimeout(debounceTimeout);
    debounceTimeout = setTimeout(() => func(), delay);
};

const handlePartnersCategory = async (updatedItemId: any) => {
    if (requestCache.has(updatedItemId)) return;
    requestCache.add(updatedItemId);

    try {
        const currentStore = await store.getState();
        const category = currentStore.categoryReducer.category;
        const item = currentStore.itemReducer.item;
        const previousItem = currentStore.itemReducer.previousItem;

        const updatedItemOnList = item?.requisites?.find(
            (requisite: any) => requisite.id === updatedItemId
        );

        const previousItemOnList = previousItem?.requisites?.find(
            (requisite: any) => requisite.id === updatedItemId
        );

        if (!updatedItemOnList) return;

        const areSame =
            updatedItemOnList.is_active === previousItemOnList?.is_active;

        if (!item || !item?.requisites || areSame) return;

        const fieldObject = category.inputFields.some(
            (field: any) => field.type === "order-items"
        );

        if (!fieldObject) return;

        if (category.getItemUrl && updatedItemOnList && item.id) {
            debounce(async () => {
                await getItem(category.getItemUrl, {
                    id: item.id,
                });
            }, 300);
        }
    } finally {
        requestCache.delete(updatedItemId);
    }
};
