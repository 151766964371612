import {
    ButtonCategory,
    Category,
    MainCategory,
} from "../types/categoriesTypes";
import { EQUALS } from "./operations";

export const categoriesData: (MainCategory | Category | ButtonCategory)[] = [
    {
        icon: (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="none"
            >
                <path
                    fill="currentColor"
                    fillRule="evenodd"
                    d="M10 3a7 7 0 100 14 7 7 0 000-14zm-9 7a9 9 0 1118 0 9 9 0 01-18 0zm8-4a1 1 0 011-1h.01a1 1 0 110 2H10a1 1 0 01-1-1zm.01 8a1 1 0 102 0V9a1 1 0 10-2 0v5z"
                />
            </svg>
        ),
        label: "Реквизиты",
        link: "/",
        addUrl: "/api/v1/user/requisite/create/",
        getUrl: "/api/v1/user/requisite/list/",
        editUrl: "/api/v1/user/requisite/update/$id/",
        deleteUrl: "/api/v1/user/requisite/delete/$id",
        getItemUrl: "/api/v1/user/requisite/get/$id/",
        filters: [
            {
                name: "Активен/нет",
                field: "is_active",
                type: "radio",
                choices: [
                    { name: "Активен", value: true },
                    { name: "Неактивен", value: false },
                ],
            },
            {
                name: "Партнер",
                field: "partner_id",
                type: "radio",
                choices: {
                    getUrl: "/api/v1/user/list?encoded_filters=W1sicm9sZSIsIj0iLCJwYXJ0bmVyIl1d",
                    nameField: "tg_username",
                    valueField: "id",
                },
            },
        ],
        fields: [
            { name: "#", field: "id" },
            {
                name: "Дата создания",
                field: "created_at",
            },
            {
                name: "Активен/нет",
                field: "status",
            },
            {
                name: "Банк",
                field: "bank",
            },
            {
                name: "Номер карты",
                field: "card_number",
            },
            {
                name: "Лимиты",
                field: "limits",
            },
            {
                name: "ФИО",
                field: "full_name",
            },
            {
                name: "Номер телефона",
                field: "phone_number",
            },
        ],
        addItemFields: [
            {
                name: "Банк",
                field_name: "bank",
                type: "text",
            },
            {
                name: "Номер карты",
                field_name: "card_number",
                type: "text",
            },
            {
                name: "Лимиты",
                field_name: "limits",
                type: "text",
            },
            {
                name: "ФИО",
                field_name: "full_name",
                type: "text",
            },
            {
                name: "Номер телефона",
                field_name: "phone_number",
                type: "text",
            },
            {
                name: "Партнер",
                field_name: "partner_id",
                type: "list-radio",
                getUrl: "/api/v1/user/list?encoded_filters=W1sicm9sZSIsIj0iLCJwYXJ0bmVyIl1d",
                labelField: "tg_username",
            },
        ],
        inputFields: [
            { name: "Номер", field_name: "id", type: "text" },
            {
                name: "Активен/нет",
                field_name: "is_active",
                type: "status",
                locked: true,
            },
            {
                name: "Банк",
                field_name: "bank",
                type: "text",
            },
            {
                name: "Номер карты",
                field_name: "card_number",
                type: "text",
            },
            {
                name: "Лимиты",
                field_name: "limits",
                type: "text",
            },
            {
                name: "ФИО",
                field_name: "full_name",
                type: "text",
            },
            {
                name: "Номер телефона",
                field_name: "phone_number",
                type: "text",
            },
            {
                name: "Партнер",
                field_name: "partner_id",
                type: "list-radio",
                getUrl: "/api/v1/user/list?encoded_filters=W1sicm9sZSIsIj0iLCJwYXJ0bmVyIl1d",
                labelField: "tg_username",
            },
        ],
    },
    {
        icon: (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
                <path d="M0 0h48v48H0z" fill="none" />
                <path
                    fill="currentColor"
                    d="M35.23,18.781C37.491,17.367,39,14.863,39,12c0-4.418-3.582-8-8-8s-8,3.582-8,8c0,2.863,1.509,5.367,3.77,6.781   c-1.423,0.537-2.714,1.334-3.821,2.337C22.51,17.115,19.12,14,15,14c-4.418,0-8,3.582-8,8c0,2.863,1.509,5.367,3.77,6.781   C6.232,30.492,3,34.862,3,40h24c0-4.177-2.134-7.851-5.37-10H43C43,24.862,39.768,20.492,35.23,18.781z M31,20   c1.18,0,2.296-0.262,3.304-0.72L31,24l-3.304-4.72C28.704,19.738,29.82,20,31,20z M15,30c1.18,0,2.296-0.262,3.304-0.72L15,34   l-3.304-4.72C12.704,29.738,13.82,30,15,30z"
                />
            </svg>
        ),
        label: "Партнеры",
        link: "/partners",
        addUrl: "/api/v1/user/create/",
        getUrl: "/api/v1/user/list/",
        editUrl: "/api/v1/user/update/$id/",
        deleteUrl: "/api/v1/user/delete/$id",
        getItemUrl: "/api/v1/user/get/$id/",
        defaultFilters: [["role", EQUALS, "partner"]],
        additionalData: { role: "partner" },
        filters: [],
        fields: [
            { name: "#", field: "id" },
            {
                name: "Telegram ник",
                field: "tg_username",
            },
            {
                name: "Ключ авторизации",
                field: "auth_key",
            },
        ],
        addItemFields: [
            {
                name: "Telegram ник",
                field_name: "tg_username",
                type: "text",
            },
        ],
        inputFields: [
            { name: "Номер", field_name: "id", type: "text" },
            {
                name: "Telegram ник",
                field_name: "tg_username",
                type: "text",
            },
            {
                name: "Ключ авторизации",
                field_name: "auth_key",
                type: "text",
                locked: true,
            },
            {
                name: "Реквизиты",
                field_name: "requisites",
                type: "order-items",
            },
        ],
    },
    {
        icon: (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                <rect x="0" fill="none" width="20" height="20" />
                <path
                    fill="currentColor"
                    d="M16.95 2.58c1.96 1.95 1.96 5.12 0 7.07-1.51 1.51-3.75 1.84-5.59 1.01l-1.87 3.31-2.99.31L5 18H2l-1-2 7.95-7.69c-.92-1.87-.62-4.18.93-5.73 1.95-1.96 5.12-1.96 7.07 0zm-2.51 3.79c.74 0 1.33-.6 1.33-1.34 0-.73-.59-1.33-1.33-1.33-.73 0-1.33.6-1.33 1.33 0 .74.6 1.34 1.33 1.34z"
                />
            </svg>
        ),
        label: "Операторы",
        link: "/operators",
        addUrl: "/api/v1/user/create/",
        getUrl: "/api/v1/user/list/",
        editUrl: "/api/v1/user/update/$id/",
        deleteUrl: "/api/v1/user/delete/$id",
        getItemUrl: "/api/v1/user/get/$id/",
        defaultFilters: [["role", EQUALS, "operator"]],
        additionalData: { role: "operator" },
        filters: [],
        fields: [
            { name: "#", field: "id" },
            {
                name: "Email",
                field: "email",
            },
            {
                name: "Главный оператор",
                field: "is_main_admin",
            },
        ],
        addItemFields: [
            {
                name: "Email",
                field_name: "email",
                type: "text",
            },
            {
                name: "Пароль",
                field_name: "password",
                type: "text",
            },
            {
                name: "Главный оператор",
                field_name: "is_main_admin",
                type: "checkbox",
            },
        ],
        inputFields: [
            { name: "Номер", field_name: "id", type: "text" },
            {
                name: "Email",
                field_name: "email",
                type: "text",
            },
            {
                name: "Пароль",
                field_name: "password",
                type: "text",
            },
            {
                name: "Главный оператор",
                field_name: "is_main_admin",
                type: "checkbox",
            },
        ],
    },
    {
        icon: (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="none"
            >
                <path
                    fill="currentColor"
                    fillRule="evenodd"
                    d="M10 3a7 7 0 100 14 7 7 0 000-14zm-9 7a9 9 0 1118 0 9 9 0 01-18 0zm8-4a1 1 0 011-1h.01a1 1 0 110 2H10a1 1 0 01-1-1zm.01 8a1 1 0 102 0V9a1 1 0 10-2 0v5z"
                />
            </svg>
        ),
        label: "Реквизиты телеграм",
        link: "/tg_requisites",
        addUrl: "/api/v1/bot/requisites/create/",
        getUrl: "/api/v1/bot/requisites/list/",
        editUrl: "/api/v1/bot/requisites/update/$id/",
        deleteUrl: "/api/v1/bot/requisites/delete/$id",
        getItemUrl: "/api/v1/bot/requisites/get/$id/",
        filters: [],
        fields: [
            { name: "#", field: "id" },
            {
                name: "Банк",
                field: "bank",
            },
            {
                name: "Реквизиты",
                field: "requisite",
            },
        ],
        addItemFields: [
            {
                name: "Банк",
                field_name: "bank",
                type: "text",
            },
            {
                name: "Реквизиты",
                field_name: "requisite",
                type: "text",
            },
        ],
        inputFields: [
            { name: "Номер", field_name: "id", type: "text" },
            {
                name: "Банк",
                field_name: "bank",
                type: "text",
            },
            {
                name: "Реквизиты",
                field_name: "requisite",
                type: "text",
            },
        ],
    },
    {
        icon: (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="none"
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M3.75 6.75L4.5 6H20.25L21 6.75V10.7812H20.25C19.5769 10.7812 19.0312 11.3269 19.0312 12C19.0312 12.6731 19.5769 13.2188 20.25 13.2188H21V17.25L20.25 18L4.5 18L3.75 17.25V13.2188H4.5C5.1731 13.2188 5.71875 12.6731 5.71875 12C5.71875 11.3269 5.1731 10.7812 4.5 10.7812H3.75V6.75ZM5.25 7.5V9.38602C6.38677 9.71157 7.21875 10.7586 7.21875 12C7.21875 13.2414 6.38677 14.2884 5.25 14.614V16.5L9 16.5L9 7.5H5.25ZM10.5 7.5V16.5L19.5 16.5V14.614C18.3632 14.2884 17.5312 13.2414 17.5312 12C17.5312 10.7586 18.3632 9.71157 19.5 9.38602V7.5H10.5Z"
                    fill="currentColor"
                />
            </svg>
        ),
        label: "Промокоды",
        link: "/promos",
        addUrl: "/api/v1/bot/promo/create/",
        getUrl: "/api/v1/bot/promo/list/",
        editUrl: "/api/v1/bot/promo/update/$id/",
        deleteUrl: "/api/v1/bot/promo/delete/$id",
        getItemUrl: "/api/v1/bot/promo/get/$id/",
        filters: [],
        fields: [
            { name: "#", field: "id" },
            {
                name: "Код",
                field: "code",
            },
            {
                name: "Скидка",
                field: "discount",
                suffix: "%",
            },
        ],
        addItemFields: [
            {
                name: "Код",
                field_name: "code",
                type: "text",
            },
            {
                name: "Скидка",
                field_name: "discount",
                type: "number",
                suffix: "%",
            },
        ],
        inputFields: [
            { name: "Номер", field_name: "id", type: "text" },

            {
                name: "Код",
                field_name: "code",
                type: "text",
            },
            {
                name: "Скидка",
                field_name: "discount",
                type: "number",
                suffix: "%",
            },
        ],
    },
    {
        icon: (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="none"
            >
                <rect
                    x="5"
                    y="4"
                    width="14"
                    height="17"
                    rx="2"
                    stroke="currentColor"
                    strokeWidth="2"
                />
                <path
                    d="M9 9H15"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                />
                <path
                    d="M9 13H15"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                />
                <path
                    d="M9 17H13"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                />
            </svg>
        ),
        label: "Заказы в боте",
        link: "/bot_orders",
        getUrl: "/api/v1/bot/order/list/",
        editUrl: "/api/v1/bot/order/update/$id/",
        deleteUrl: "/api/v1/bot/order/delete/$id",
        getItemUrl: "/api/v1/bot/order/get/$id/",
        filters: [],
        fields: [
            { name: "#", field: "id" },
            {
                name: "Ник пользователя",
                field: "bot_user.username",
            },
            {
                name: "Количество USDT",
                field: "usdt_amount",
            },
            {
                name: "Цена",
                field: "total_price",
            },
            {
                name: "Статус заказа",
                field: "order-status",
            },
        ],
        addItemFields: [],
        inputFields: [
            { name: "Номер", field_name: "id", type: "text" },
            {
                name: "Статус заказа",
                field_name: "status",
                type: "order-status",
                value: [
                    { name: "Завершенный", value: "completed" },
                    { name: "В обработке", value: "accepted" },
                    {
                        name: "Отмененный",
                        value: "cancelled",
                    },
                ],
                labelField: "name",
            },
            {
                name: "Ник пользователя",
                field_name: "bot_user.username",
                type: "text",
                locked: true,
            },
            {
                name: "Банк",
                field_name: "requisite.bank",
                type: "text",
                locked: true,
            },
            {
                name: "Реквизиты",
                field_name: "requisite.requisite",
                type: "text",
                locked: true,
            },
            {
                name: "USDT Кошелек",
                field_name: "usdt_wallet",
                type: "text",
                locked: true,
            },
            {
                name: "Количество USDT",
                field_name: "usdt_amount",
                type: "text",
                locked: true,
            },
            {
                name: "Промокод",
                field_name: "promo_code.code",
                type: "text",
                locked: true,
            },
            {
                name: "Скидка",
                field_name: "promo_code.discount",
                type: "text",
                suffix: "%",
                locked: true,
            },
            {
                name: "Итоговая цена",
                field_name: "total_price",
                type: "text",
                locked: true,
            },
        ],
    },
    {
        icon: (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 32 32"
                version="1.1"
            >
                <path d="M22.122 10.040c0.006-0 0.014-0 0.022-0 0.209 0 0.403 0.065 0.562 0.177l-0.003-0.002c0.116 0.101 0.194 0.243 0.213 0.403l0 0.003c0.020 0.122 0.031 0.262 0.031 0.405 0 0.065-0.002 0.129-0.007 0.193l0-0.009c-0.225 2.369-1.201 8.114-1.697 10.766-0.21 1.123-0.623 1.499-1.023 1.535-0.869 0.081-1.529-0.574-2.371-1.126-1.318-0.865-2.063-1.403-3.342-2.246-1.479-0.973-0.52-1.51 0.322-2.384 0.221-0.23 4.052-3.715 4.127-4.031 0.004-0.019 0.006-0.040 0.006-0.062 0-0.078-0.029-0.149-0.076-0.203l0 0c-0.052-0.034-0.117-0.053-0.185-0.053-0.045 0-0.088 0.009-0.128 0.024l0.002-0.001q-0.198 0.045-6.316 4.174c-0.445 0.351-1.007 0.573-1.619 0.599l-0.006 0c-0.867-0.105-1.654-0.298-2.401-0.573l0.074 0.024c-0.938-0.306-1.683-0.467-1.619-0.985q0.051-0.404 1.114-0.827 6.548-2.853 8.733-3.761c1.607-0.853 3.47-1.555 5.429-2.010l0.157-0.031zM15.93 1.025c-8.302 0.020-15.025 6.755-15.025 15.060 0 8.317 6.742 15.060 15.060 15.060s15.060-6.742 15.060-15.060c0-8.305-6.723-15.040-15.023-15.060h-0.002q-0.035-0-0.070 0z" />
            </svg>
        ),
        label: "Основная информация бота",
        link: "/bot_info",
        addUrl: "/api/v1/bot/main_info/create/",
        getUrl: "/api/v1/bot/main_info/get/",
        editUrl: "/api/v1/bot/main_info/update/",
        deleteUrl: "/api/v1/bot/main_info/delete/",
        getItemUrl: "/api/v1/bot/main_info/get/",
        filters: [],
        fields: [
            { name: "#", field: "id" },
            {
                name: "Оператор",
                field: "operator_username",
            },
            {
                name: "Telegram ID оператора",
                field: "operator_id",
            },
            {
                name: "Оператор по работе",
                field: "job_operator_username",
            },
        ],
        addItemFields: [
            {
                name: "Оператор",
                field_name: "operator_username",
                type: "text",
            },
            {
                name: "Telegram ID оператора",
                field_name: "operator_id",
                type: "text",
            },
            {
                name: "Оператор по работе",
                field_name: "job_operator_username",
                type: "text",
            },
            {
                name: "USDT в наличии",
                field_name: "usdt_reserve",
                type: "number",
            },
        ],
        inputFields: [
            { name: "Номер", field_name: "id", type: "text" },
            {
                name: "Оператор",
                field_name: "operator_username",
                type: "text",
            },
            {
                name: "Telegram ID оператора",
                field_name: "operator_id",
                type: "text",
            },
            {
                name: "Оператор по работе",
                field_name: "job_operator_username",
                type: "text",
            },
            {
                name: "USDT в наличии",
                field_name: "usdt_reserve",
                type: "number",
            },
        ],
    },
    {
        icon: (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
                <path
                    fill="currentColor"
                    d="M17.922 17.383v-.002c-.11.008-.677.042-1.942.042-1.01 0-1.721-.03-1.971-.042v.003c-3.888-.171-6.79-.848-6.79-1.658 0-.809 2.902-1.486 6.79-1.66v2.644c.254.018.982.061 1.988.061 1.207 0 1.812-.05 1.925-.06v-2.643c3.88.173 6.775.85 6.775 1.658 0 .81-2.895 1.485-6.775 1.657m0-3.59v-2.366h5.414V7.819H8.595v3.608h5.414v2.365c-4.4.202-7.709 1.074-7.709 2.118 0 1.044 3.309 1.915 7.709 2.118v7.582h3.913v-7.584c4.393-.202 7.694-1.073 7.694-2.116 0-1.043-3.301-1.914-7.694-2.117"
                />
            </svg>
        ),
        label: "Цена USDT",
        link: "/usdt_price",
        getUrl: "/api/v1/bot/main_info/usdt_price/get",
        editUrl: "/api/v1/bot/main_info/usdt_price/update",
        getItemUrl: "/api/v1/bot/main_info/usdt_price/get",
        filters: [],
        fields: [
            {
                name: "Цена",
                field: "usdt_price",
            },
        ],
        addItemFields: [],
        inputFields: [
            {
                name: "Цена",
                field_name: "usdt_price",
                type: "number",
            },
        ],
    },
    {
        icon: (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="none"
            >
                <path
                    d="M7 19V21M17 19V21M17 11C17.5523 11 18 10.5523 18 10C18 9.44772 17.5523 9 17 9C16.4477 9 16 9.44772 16 10C16 10.5523 16.4477 11 17 11ZM17 11V14M17 10H17.01M9.5 11.5H9.51M13 11.5C13 13.433 11.433 15 9.5 15C7.567 15 6 13.433 6 11.5C6 9.567 7.567 8 9.5 8C11.433 8 13 9.567 13 11.5ZM7.8 19H16.2C17.8802 19 18.7202 19 19.362 18.673C19.9265 18.3854 20.3854 17.9265 20.673 17.362C21 16.7202 21 15.8802 21 14.2V8.8C21 7.11984 21 6.27976 20.673 5.63803C20.3854 5.07354 19.9265 4.6146 19.362 4.32698C18.7202 4 17.8802 4 16.2 4H7.8C6.11984 4 5.27976 4 4.63803 4.32698C4.07354 4.6146 3.6146 5.07354 3.32698 5.63803C3 6.27976 3 7.11984 3 8.8V14.2C3 15.8802 3 16.7202 3.32698 17.362C3.6146 17.9265 4.07354 18.3854 4.63803 18.673C5.27976 19 6.11984 19 7.8 19Z"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        ),
        label: "Резервы",
    },
];
