import React from "react";
import { useSelector } from "react-redux";
import "../styles/components/OrderItem.scss";

type OrderItemProps = {
    item: any;
};

const OrderItem = ({ item }: OrderItemProps) => {
    const currentWindWidth = useSelector(
        (state: any) => state.windowReducer.width
    );
    const fields = [
        { name: "#", field: "id" },
        {
            name: "Дата создания",
            field: "created_at",
        },
        {
            name: "Активен/нет",
            field: "status",
        },
        {
            name: "Банк",
            field: "bank",
        },
        {
            name: "Номер карты",
            field: "card_number",
        },
        {
            name: "ФИО",
            field: "full_name",
        },
        {
            name: "Номер телефона",
            field: "phone_number",
        },
    ];

    const formatDate = (dateString: string) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, "0");
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const year = date.getFullYear();

        return `${day}.${month}.${year}`;
    };

    return item && currentWindWidth > 615 ? (
        <React.Fragment>
            <tr style={{ backgroundColor: "var(--background-color-muted)" }}>
                {fields?.length > 0 &&
                    fields.map((fieldObject: any, fieldIndex: number) => {
                        const fieldName =
                            typeof fieldObject === "string"
                                ? fieldObject
                                : fieldObject.field;

                        const valueNames = (fieldObject as any)?.valueNames;

                        const displayedValue =
                            valueNames?.[item[fieldName]] || item[fieldName];

                        return (
                            <td key={fieldIndex}>
                                {typeof item[fieldName] === "boolean" ? (
                                    <input
                                        type="checkbox"
                                        checked={item[fieldName]}
                                        readOnly={true}
                                    />
                                ) : (
                                    <>
                                        {fieldName === "status" ? (
                                            <div
                                                style={{
                                                    display: "flex",
                                                    gap: "10px",
                                                    alignItems: "center",
                                                }}
                                            >
                                                <span
                                                    style={{
                                                        width: "20px",
                                                        height: "20px",
                                                        display: "flex",
                                                        borderRadius: "50%",
                                                        backgroundColor:
                                                            item?.is_active
                                                                ? "var(--green)"
                                                                : "var(--red)",

                                                        aspectRatio: "1/1",
                                                        flexShrink: "0",
                                                    }}
                                                ></span>
                                            </div>
                                        ) : fieldName === "created_at" ? (
                                            formatDate(displayedValue)
                                        ) : (
                                            displayedValue
                                        )}
                                    </>
                                )}
                            </td>
                        );
                    })}
            </tr>
        </React.Fragment>
    ) : (
        <React.Fragment>
            <div className="item">
                {fields?.length > 0 &&
                    fields.map((fieldObject: any, fieldIndex) => {
                        const fieldName =
                            typeof fieldObject === "string"
                                ? fieldObject
                                : fieldObject.field;

                        const valueNames = (fieldObject as any)?.valueNames;

                        const displayedValue =
                            valueNames?.[item[fieldName]] || item[fieldName];

                        return (
                            <div key={fieldIndex} className="row">
                                <div className="part">
                                    {fieldObject?.name || fieldObject?.field}
                                </div>

                                <div className="part">
                                    {fieldName === "status" ? (
                                        <div
                                            style={{
                                                display: "flex",
                                                gap: "10px",
                                                alignItems: "center",
                                            }}
                                        >
                                            <span
                                                style={{
                                                    width: "20px",
                                                    height: "20px",
                                                    display: "flex",
                                                    borderRadius: "50%",
                                                    backgroundColor:
                                                        item?.is_active
                                                            ? "var(--green)"
                                                            : "var(--red)",

                                                    aspectRatio: "1/1",
                                                    flexShrink: "0",
                                                }}
                                            ></span>
                                        </div>
                                    ) : fieldName === "created_at" ? (
                                        formatDate(displayedValue)
                                    ) : (
                                        displayedValue
                                    )}
                                </div>
                            </div>
                        );
                    })}
            </div>
        </React.Fragment>
    );
};

export default OrderItem;
