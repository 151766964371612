import {
    SET_PRODUCTS,
    SET_PRODUCTS_UPDATE,
} from "../actionTypes/productsActionTypes";

const initialState = {
    products: [],
    needsUpdate: false,
};

export const productsReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case SET_PRODUCTS:
            return {
                ...state,
                products: action.payload,
            };
        case SET_PRODUCTS_UPDATE:
            return {
                ...state,
                needsUpdate: action.payload,
            };
        default:
            return state;
    }
};
