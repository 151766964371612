import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
    EQUALS,
    RANGE,
    VALUE_IN,
    VALUE_LESS_THAN_OR_EQUALS,
    VALUE_MORE_THAN_OR_EQUALS,
} from "../data/operations";
import { SetCurrentAction } from "../redux/actions/currentActionActions";
import {
    SetAvailablePagesCount,
    SetCurrentPage,
} from "../redux/actions/currentPageActions";
import {
    SetProductsState,
    SetProductsUpdateState,
} from "../redux/actions/productsActions";
import "../styles/components/ActionModal.scss";
import "../styles/components/Content.scss";
import { TableField } from "../types/categoriesTypes";
import { generateUrl } from "../utils/generateUrl";
import { getItems } from "../utils/getItems";
import ActionModal from "./ActionModal";
import ItemActions from "./ItemActions";
import Pagination from "./Pagination";

const Content = () => {
    const user = useSelector((state: any) => state.userReducer.user);
    const currentWindWidth = useSelector(
        (state: any) => state.windowReducer.width
    );

    const category = useSelector(
        (state: any) => state.categoryReducer.category
    );
    const currentPage = useSelector(
        (state: any) => state.pageReducer.currentPage
    );
    const productsNeedsUpdate = useSelector(
        (state: any) => state.productsReducer.needsUpdate
    );
    const action = useSelector((state: any) => state.actionReducer.action);
    const [previousAction, setPreviousAction] = useState(action || "");
    const [previousCategory, setPreviousCategory] = useState(
        category.link || ""
    );
    const [previousFilters, setPreviousFilters] = useState([]);
    const [fields, setFields] = useState<(TableField | string)[]>([
        "id",
        "name",
        "category",
        "price",
    ]);
    const [filtersChoices, setFiltersChoices] = useState<any>({});
    const [filteredSelectOptions, setFilteredSelectOptions] = useState<any>({});
    const [filtersOpened, setFiltersOpened] = useState(false);
    const [products, setProducts] = useState<any>([]);
    const [filters, setFilters] = useState<any>([]);
    const dispatch = useDispatch();
    const params = useParams();

    const filterBySearchPrompt = (
        optionsToFilter: any,
        filterField: any,
        prompt: any
    ) => {
        const lowerCasedPrompt = prompt?.toLowerCase();

        return prompt
            ? optionsToFilter
                  .map((option: any) => {
                      const lowerCasedName =
                          option?.[filterField].toLowerCase();

                      const matchIndex =
                          lowerCasedName.indexOf(lowerCasedPrompt);

                      let accuracy = 0;
                      if (matchIndex !== -1) {
                          accuracy =
                              (lowerCasedPrompt.length * 100) /
                              lowerCasedName.length;
                      }

                      return {
                          ...option,
                          accuracy,
                      };
                  })
                  .filter((option: any) => option.accuracy > 0)
                  .sort((a: any, b: any) => b.accuracy - a.accuracy)
            : optionsToFilter;
    };

    const setUpTable = async () => {
        const currentFilters = [
            ...(filters || []),
            ...(category?.defaultFilters || []),
        ];
        let currentFields = category.fields;

        if (category.link === "/operators" && !user?.is_main_admin) {
            currentFilters.push(["is_main_admin", EQUALS, false]);
            currentFields = currentFields.filter(
                (field: any) => field.field !== "is_main_admin"
            );
        }

        const areFiltersValid =
            currentFilters &&
            Array.isArray(currentFilters) &&
            currentFilters.length > 0;

        const readyFilters = areFiltersValid ? currentFilters : undefined;

        const items =
            category.getUrl &&
            (await getItems(category.getUrl, readyFilters, true).then((res) =>
                !Array.isArray(res) ? [res] : res
            ));

        const currentProducts = items || [];

        dispatch(SetProductsState(currentProducts));
        setProducts(currentProducts);
        setFields(currentFields);

        if (productsNeedsUpdate) {
            dispatch(SetProductsUpdateState(false));
        }
    };

    const formatDate = (dateString: string) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, "0");
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const year = date.getFullYear();

        return `${day}.${month}.${year}`;
    };

    useEffect(() => {
        if (
            (!category.main && !category.letter) ||
            (action === "" &&
                (previousAction === "add" ||
                    previousAction === "edit" ||
                    previousAction === "delete"))
        ) {
            setUpTable();
        }

        if (
            (category.link !== previousCategory ||
                filters !== previousFilters) &&
            !productsNeedsUpdate
        ) {
            dispatch(SetCurrentPage(1));
            dispatch(SetAvailablePagesCount(1));

            if (category.link !== previousCategory) {
                setPreviousCategory(category.link);
                setFilters([]);
                setFiltersOpened(false);
            }
        }

        if (
            Object.keys(filtersChoices).length <
                Object.keys(category?.filters).length &&
            category?.filters &&
            category?.filters?.length > 0
        ) {
            const fetchFilterChoices = async () => {
                if (!category?.filters) return;

                const formatChoices = (filter: any, data: any) => {
                    let currentChoices = [...(data || [])];

                    if (currentChoices.length > 0) {
                        currentChoices = currentChoices.map((choice) => ({
                            name: choice[filter.choices?.nameField],
                            value: choice[filter.choices?.valueField],
                        }));
                    }

                    return currentChoices;
                };

                const currentFiltersChoices = await category.filters.reduce(
                    async (
                        accPromise: Promise<{ [key: string]: any[] }>,
                        filter: any
                    ) => {
                        const acc = await accPromise;

                        const filterChoices =
                            filter?.choices && !filter.choices?.getUrl
                                ? filter.choices
                                : filter?.choices?.getUrl
                                ? await axios
                                      .get(generateUrl(filter.choices.getUrl))
                                      .then((res) =>
                                          formatChoices(filter, res.data)
                                      )
                                      .catch(() => [])
                                : [];

                        return { ...acc, [filter.field]: filterChoices };
                    },
                    Promise.resolve({})
                );

                setFiltersChoices(currentFiltersChoices);
                setFilteredSelectOptions(currentFiltersChoices);
            };

            fetchFilterChoices();
        }

        setPreviousFilters(filters);
        setPreviousAction(action);
    }, [
        user,
        category,
        params.category,
        action,
        currentPage,
        filters,
        productsNeedsUpdate,
    ]);

    const getListInput = (
        fieldName: string,
        options: any,
        isSingle?: boolean
    ) => {
        const onChange = (e: any, option: any) => {
            setFilters((prev: any) => {
                const currentValue = [...(prev || [])];

                if (currentValue.length > 0) {
                    const filtersHasOptions = filters.some(
                        (filter: any) => filter[0] === fieldName
                    );

                    if (filtersHasOptions) {
                        currentValue.forEach(
                            (prevFilter: any, index: number) => {
                                if (prevFilter[0] === fieldName) {
                                    if (
                                        isSingle ||
                                        prevFilter[2] === undefined
                                    ) {
                                        prevFilter[2] = option.value;
                                        prevFilter[1] = EQUALS;
                                    } else if (Array.isArray(prevFilter[2])) {
                                        const doesValueIncludeCurrentOptionValue =
                                            prevFilter[2].includes(
                                                option.value
                                            );

                                        if (
                                            doesValueIncludeCurrentOptionValue
                                        ) {
                                            prevFilter[2] =
                                                prevFilter[2].filter(
                                                    (value: any) =>
                                                        value !== option.value
                                                );

                                            if (
                                                prevFilter[2].length > 0 &&
                                                prevFilter[2].length === 1
                                            ) {
                                                prevFilter[2] =
                                                    prevFilter[2][0];
                                                prevFilter[1] = EQUALS;
                                            } else if (
                                                prevFilter[2].length > 1
                                            ) {
                                                prevFilter[1] = VALUE_IN;
                                            }
                                        } else if (
                                            !doesValueIncludeCurrentOptionValue
                                        ) {
                                            prevFilter[2].push(option.value);
                                            prevFilter[1] = VALUE_IN;
                                        }
                                    } else if (prevFilter[2] !== undefined) {
                                        if (e.target.checked) {
                                            prevFilter[2] = [
                                                prevFilter[2],
                                                option.value,
                                            ];
                                            prevFilter[1] = VALUE_IN;
                                        } else {
                                            currentValue.splice(index, 1);
                                        }
                                    }
                                }
                            }
                        );
                    } else {
                        currentValue.push([fieldName, EQUALS, option.value]);
                    }
                } else {
                    currentValue.push([fieldName, EQUALS, option.value]);
                }

                return currentValue;
            });
        };

        const isChecked = (value: any) => {
            return Array.isArray(filters)
                ? filters.some(
                      (filter: any) =>
                          filter[0] === fieldName &&
                          (filter[2] === value ||
                              (Array.isArray(filter[2]) &&
                                  filter[2].includes(value)))
                  )
                : false;
        };

        return (
            <div className="input-filter-container">
                <ul className="list-input" id={fieldName}>
                    {options.length > 0 &&
                        options.map((option: any, index: number) => (
                            <li
                                key={`${fieldName}[${index}]`}
                                id={`${fieldName}[${index}]`}
                            >
                                <input
                                    name={fieldName}
                                    type={!isSingle ? "checkbox" : "radio"}
                                    checked={isChecked(option.value)}
                                    onChange={(e) => onChange(e, option)}
                                    // {...(isSingle
                                    //     ? {
                                    //           onClick: (e: any) =>
                                    //               e.target.checked
                                    //                   ? setFilters(
                                    //                         (prev: any) => {
                                    //                             const currentValue =
                                    //                                 [
                                    //                                     ...(prev ||
                                    //                                         []),
                                    //                                 ];

                                    //                             currentValue.forEach(
                                    //                                 (
                                    //                                     filter: any,
                                    //                                     index: number
                                    //                                 ) => {
                                    //                                     if (
                                    //                                         !Array.isArray(
                                    //                                             filter[2]
                                    //                                         )
                                    //                                     ) {
                                    //                                         currentValue.splice(
                                    //                                             index,
                                    //                                             1
                                    //                                         );
                                    //                                     } else {
                                    //                                         filter[2] =
                                    //                                             filter[2].filter(
                                    //                                                 (
                                    //                                                     value: any
                                    //                                                 ) =>
                                    //                                                     value !==
                                    //                                                     option.value
                                    //                                             );
                                    //                                     }
                                    //                                 }
                                    //                             );

                                    //                             return currentValue;
                                    //                         }
                                    //                     )
                                    //                   : null,
                                    //       }
                                    //     : {})}
                                />
                                {option.name}
                            </li>
                        ))}
                </ul>
            </div>
        );
    };

    const getNumberInput = (fieldName: string) => {
        const onChange = (value: number, isMax?: boolean) => {
            const valueInt = +value;

            setFilters((prev: any) => {
                const currentValue = [...(prev || [])];

                const isFieldInFilters = filters.some(
                    (filter: any) => filter[0] === fieldName
                );

                if (isFieldInFilters) {
                    currentValue.forEach((filter: any, index: number) => {
                        if (filter[0] === fieldName) {
                            const notUndefinedValue = Array.isArray(filter[2])
                                ? filter[2].find(
                                      (item: any) => item !== undefined
                                  )
                                : filter[2];

                            if (!notUndefinedValue) {
                                currentValue.splice(index, 1);
                                return;
                            }

                            if (!valueInt && Array.isArray(filter[2])) {
                                filter[2] = filter[2][isMax ? 0 : 1];
                                filter[1] = isMax
                                    ? VALUE_MORE_THAN_OR_EQUALS
                                    : VALUE_LESS_THAN_OR_EQUALS;
                                return;
                            } else if (!valueInt) {
                                currentValue.splice(index, 1);
                                return;
                            }

                            if (filter[2] === undefined) {
                                filter[2] = valueInt;
                            } else if (
                                filter[2] !== undefined &&
                                !Array.isArray(filter[2])
                            ) {
                                if (filter[1] === VALUE_MORE_THAN_OR_EQUALS) {
                                    filter[2] = !isMax
                                        ? valueInt
                                        : [filter[2], valueInt];

                                    if (isMax) {
                                        filter[1] = RANGE;
                                    }
                                } else if (
                                    filter[1] === VALUE_LESS_THAN_OR_EQUALS
                                ) {
                                    filter[2] = isMax
                                        ? valueInt
                                        : [valueInt, filter[2]];

                                    if (!isMax) {
                                        filter[1] = RANGE;
                                    }
                                }
                            } else if (
                                filter[2] !== undefined &&
                                Array.isArray(filter[2])
                            ) {
                                filter[2][isMax ? 1 : 0] = valueInt;
                            }
                        }
                    });
                } else {
                    currentValue.push([
                        fieldName,
                        isMax
                            ? VALUE_LESS_THAN_OR_EQUALS
                            : VALUE_MORE_THAN_OR_EQUALS,
                        valueInt,
                    ]);
                }

                return currentValue;
            });
        };
        return (
            <div className="input-filter-container from-to-input">
                <input
                    placeholder="Мінімальне значення"
                    className="filter-input"
                    type="number"
                    onChange={(e: any) => onChange(e.target.value)}
                />
                <span></span>
                <input
                    placeholder="Максимальне значення"
                    className="filter-input"
                    type="number"
                    onChange={(e: any) => onChange(e.target.value, true)}
                />
            </div>
        );
    };

    return (
        <div className="content">
            {category && (
                <>
                    <ActionModal />
                    <p className="category-title">
                        <span>{category.label}</span>

                        {((category.addUrl &&
                            category.link !== "/bot_info" &&
                            category.link !== "/operators") ||
                            (category.link === "/bot_info" &&
                                products.length < 1) ||
                            (category.link === "/operators" &&
                                user?.is_main_admin)) && (
                            <button
                                className="active add-item"
                                onClick={() => {
                                    dispatch(SetCurrentAction("add"));
                                }}
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                    fill="currentColor"
                                    version="1.1"
                                    viewBox="0 0 490 490"
                                    xmlSpace="preserve"
                                >
                                    <path d="M227.8,174.1v53.7h-53.7c-9.5,0-17.2,7.7-17.2,17.2s7.7,17.2,17.2,17.2h53.7v53.7c0,9.5,7.7,17.2,17.2,17.2     s17.1-7.7,17.1-17.2v-53.7h53.7c9.5,0,17.2-7.7,17.2-17.2s-7.7-17.2-17.2-17.2h-53.7v-53.7c0-9.5-7.7-17.2-17.1-17.2     S227.8,164.6,227.8,174.1z" />
                                    <path d="M71.7,71.7C25.5,118,0,179.5,0,245s25.5,127,71.8,173.3C118,464.5,179.6,490,245,490s127-25.5,173.3-71.8     C464.5,372,490,310.4,490,245s-25.5-127-71.8-173.3C372,25.5,310.5,0,245,0C179.6,0,118,25.5,71.7,71.7z M455.7,245     c0,56.3-21.9,109.2-61.7,149s-92.7,61.7-149,61.7S135.8,433.8,96,394s-61.7-92.7-61.7-149S56.2,135.8,96,96s92.7-61.7,149-61.7     S354.2,56.2,394,96S455.7,188.7,455.7,245z" />
                                </svg>
                            </button>
                        )}
                    </p>

                    {!category.main && !category.letter && (
                        <>
                            {category?.filters &&
                            category?.filters?.length > 0 ? (
                                <>
                                    <div
                                        className={`filters-inputs-container${
                                            filtersOpened ? " active" : ""
                                        }`}
                                    >
                                        {category.filters.map(
                                            (filter: any, index: number) => (
                                                <div
                                                    key={`filter[${filter.name}-${index}]`}
                                                >
                                                    <p className="filter-name">
                                                        {filter.name}
                                                    </p>

                                                    {filter.field ===
                                                        "partner_id" && (
                                                        <input
                                                            style={{
                                                                marginTop:
                                                                    "10px",
                                                            }}
                                                            onChange={(e) =>
                                                                setFilteredSelectOptions(
                                                                    (
                                                                        prev: any
                                                                    ) => {
                                                                        const currentFilteredSelectOptions =
                                                                            {
                                                                                ...prev,
                                                                            };

                                                                        currentFilteredSelectOptions[
                                                                            filter.field
                                                                        ] =
                                                                            filterBySearchPrompt(
                                                                                filtersChoices[
                                                                                    filter
                                                                                        .field
                                                                                ],
                                                                                "name",
                                                                                e
                                                                                    .target
                                                                                    .value ||
                                                                                    ""
                                                                            );

                                                                        return currentFilteredSelectOptions;
                                                                    }
                                                                )
                                                            }
                                                            placeholder="Найти из списка"
                                                        />
                                                    )}

                                                    {Array.isArray(
                                                        filteredSelectOptions?.[
                                                            filter.field
                                                        ]
                                                    )
                                                        ? getListInput(
                                                              filter.field,
                                                              filteredSelectOptions?.[
                                                                  filter.field
                                                              ],
                                                              filter.type ===
                                                                  "radio"
                                                          )
                                                        : getNumberInput(
                                                              filter.field
                                                          )}
                                                </div>
                                            )
                                        )}
                                    </div>

                                    <button
                                        className="default-button"
                                        onClick={() =>
                                            setFiltersOpened(!filtersOpened)
                                        }
                                    >
                                        {!filtersOpened
                                            ? "Открыть фильтры"
                                            : "Закрыть фильтры"}
                                    </button>
                                </>
                            ) : null}
                        </>
                    )}

                    {
                        <>
                            {currentWindWidth > 615 ? (
                                <table>
                                    <thead>
                                        <tr>
                                            {fields?.length > 0 &&
                                                fields.map(
                                                    (
                                                        fieldObject:
                                                            | TableField
                                                            | string,
                                                        index
                                                    ) => {
                                                        const fieldName =
                                                            typeof fieldObject ===
                                                            "string"
                                                                ? fieldObject
                                                                : fieldObject.name;
                                                        return (
                                                            <th key={index}>
                                                                <div>
                                                                    <span>
                                                                        {
                                                                            fieldName
                                                                        }
                                                                    </span>
                                                                </div>
                                                            </th>
                                                        );
                                                    }
                                                )}
                                            <th>Действия</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {products?.length > 0 &&
                                            products.map(
                                                (
                                                    product: any,
                                                    index: number
                                                ) => (
                                                    <React.Fragment key={index}>
                                                        <tr>
                                                            {fields?.length >
                                                                0 &&
                                                                fields.map(
                                                                    (
                                                                        fieldObject: any,
                                                                        fieldIndex
                                                                    ) => {
                                                                        const fieldName =
                                                                            typeof fieldObject ===
                                                                            "string"
                                                                                ? fieldObject
                                                                                : fieldObject.field;

                                                                        const valueNames =
                                                                            (
                                                                                fieldObject as any
                                                                            )
                                                                                ?.valueNames;

                                                                        const getValueByPath =
                                                                            (
                                                                                obj: any,
                                                                                path: string
                                                                            ): any => {
                                                                                return path
                                                                                    .split(
                                                                                        "."
                                                                                    )
                                                                                    .reduce(
                                                                                        (
                                                                                            acc,
                                                                                            key
                                                                                        ) => {
                                                                                            return acc &&
                                                                                                acc[
                                                                                                    key
                                                                                                ] !==
                                                                                                    undefined
                                                                                                ? acc[
                                                                                                      key
                                                                                                  ]
                                                                                                : undefined;
                                                                                        },
                                                                                        obj
                                                                                    );
                                                                            };

                                                                        const displayedValue =
                                                                            valueNames?.[
                                                                                getValueByPath(
                                                                                    product,
                                                                                    fieldName
                                                                                )
                                                                            ] ||
                                                                            getValueByPath(
                                                                                product,
                                                                                fieldName
                                                                            );

                                                                        const fieldNameSplitted =
                                                                            fieldName.split(
                                                                                "."
                                                                            );

                                                                        return (
                                                                            <td
                                                                                key={
                                                                                    fieldIndex
                                                                                }
                                                                            >
                                                                                {typeof product[
                                                                                    fieldName
                                                                                ] ===
                                                                                "boolean" ? (
                                                                                    <input
                                                                                        type="checkbox"
                                                                                        checked={
                                                                                            product[
                                                                                                fieldName
                                                                                            ]
                                                                                        }
                                                                                        readOnly={
                                                                                            true
                                                                                        }
                                                                                    />
                                                                                ) : (
                                                                                    <>
                                                                                        {fieldName ===
                                                                                        "status" ? (
                                                                                            <div
                                                                                                style={{
                                                                                                    display:
                                                                                                        "flex",
                                                                                                    gap: "10px",
                                                                                                    alignItems:
                                                                                                        "center",
                                                                                                }}
                                                                                            >
                                                                                                <span
                                                                                                    style={{
                                                                                                        width: "20px",
                                                                                                        height: "20px",
                                                                                                        display:
                                                                                                            "flex",
                                                                                                        borderRadius:
                                                                                                            "50%",
                                                                                                        backgroundColor:
                                                                                                            product?.is_active
                                                                                                                ? "var(--green)"
                                                                                                                : "var(--red)",

                                                                                                        aspectRatio:
                                                                                                            "1/1",
                                                                                                        flexShrink:
                                                                                                            "0",
                                                                                                    }}
                                                                                                ></span>
                                                                                            </div>
                                                                                        ) : fieldName ===
                                                                                          "order-status" ? (
                                                                                            product?.status ===
                                                                                            "cancelled" ? (
                                                                                                "Отмененный"
                                                                                            ) : product?.status ===
                                                                                              "completed" ? (
                                                                                                "Завершенный"
                                                                                            ) : (
                                                                                                "В обработке"
                                                                                            )
                                                                                        ) : fieldName ===
                                                                                          "created_at" ? (
                                                                                            formatDate(
                                                                                                displayedValue
                                                                                            )
                                                                                        ) : (
                                                                                            <>
                                                                                                {displayedValue ||
                                                                                                    "Не установлено"}
                                                                                                {
                                                                                                    fieldObject.suffix
                                                                                                }
                                                                                            </>
                                                                                        )}
                                                                                    </>
                                                                                )}
                                                                            </td>
                                                                        );
                                                                    }
                                                                )}

                                                            <ItemActions
                                                                id={product.id}
                                                            />
                                                        </tr>
                                                    </React.Fragment>
                                                )
                                            )}
                                    </tbody>
                                </table>
                            ) : (
                                <div className="mobile-table">
                                    {products?.length > 0 &&
                                        products.map(
                                            (product: any, index: number) => (
                                                <React.Fragment key={index}>
                                                    <div className="item">
                                                        <ItemActions
                                                            id={product.id}
                                                        />
                                                        {fields?.length > 0 &&
                                                            fields.map(
                                                                (
                                                                    fieldObject: any,
                                                                    fieldIndex
                                                                ) => {
                                                                    const fieldName =
                                                                        typeof fieldObject ===
                                                                        "string"
                                                                            ? fieldObject
                                                                            : fieldObject.field;

                                                                    const valueNames =
                                                                        (
                                                                            fieldObject as any
                                                                        )
                                                                            ?.valueNames;

                                                                    const getValueByPath =
                                                                        (
                                                                            obj: any,
                                                                            path: string
                                                                        ): any => {
                                                                            return path
                                                                                .split(
                                                                                    "."
                                                                                )
                                                                                .reduce(
                                                                                    (
                                                                                        acc,
                                                                                        key
                                                                                    ) => {
                                                                                        return acc &&
                                                                                            acc[
                                                                                                key
                                                                                            ] !==
                                                                                                undefined
                                                                                            ? acc[
                                                                                                  key
                                                                                              ]
                                                                                            : undefined;
                                                                                    },
                                                                                    obj
                                                                                );
                                                                        };

                                                                    const displayedValue =
                                                                        valueNames?.[
                                                                            getValueByPath(
                                                                                product,
                                                                                fieldName
                                                                            )
                                                                        ] ||
                                                                        getValueByPath(
                                                                            product,
                                                                            fieldName
                                                                        );

                                                                    return (
                                                                        <div
                                                                            key={
                                                                                fieldIndex
                                                                            }
                                                                            className="row"
                                                                        >
                                                                            <div className="part">
                                                                                {(
                                                                                    fields?.[
                                                                                        fieldIndex
                                                                                    ] as any
                                                                                )
                                                                                    ?.name ||
                                                                                    (
                                                                                        fields?.[
                                                                                            fieldIndex
                                                                                        ] as any
                                                                                    )
                                                                                        ?.field}
                                                                            </div>

                                                                            <div className="part">
                                                                                {typeof product[
                                                                                    fieldName
                                                                                ] ===
                                                                                "boolean" ? (
                                                                                    <input
                                                                                        type="checkbox"
                                                                                        checked={
                                                                                            product[
                                                                                                fieldName
                                                                                            ]
                                                                                        }
                                                                                        readOnly={
                                                                                            true
                                                                                        }
                                                                                    />
                                                                                ) : (
                                                                                    <>
                                                                                        {fieldName ===
                                                                                        "status" ? (
                                                                                            <div
                                                                                                style={{
                                                                                                    display:
                                                                                                        "flex",
                                                                                                    gap: "10px",
                                                                                                    alignItems:
                                                                                                        "center",
                                                                                                }}
                                                                                            >
                                                                                                <span
                                                                                                    style={{
                                                                                                        width: "20px",
                                                                                                        height: "20px",
                                                                                                        display:
                                                                                                            "flex",
                                                                                                        borderRadius:
                                                                                                            "50%",
                                                                                                        backgroundColor:
                                                                                                            product?.is_active
                                                                                                                ? "var(--green)"
                                                                                                                : "var(--red)",

                                                                                                        aspectRatio:
                                                                                                            "1/1",
                                                                                                        flexShrink:
                                                                                                            "0",
                                                                                                    }}
                                                                                                ></span>
                                                                                            </div>
                                                                                        ) : fieldName ===
                                                                                          "order-status" ? (
                                                                                            product?.status ===
                                                                                            "cancelled" ? (
                                                                                                "Отмененный"
                                                                                            ) : product?.status ===
                                                                                              "completed" ? (
                                                                                                "Завершенный"
                                                                                            ) : (
                                                                                                "В обработке"
                                                                                            )
                                                                                        ) : fieldName ===
                                                                                          "created_at" ? (
                                                                                            formatDate(
                                                                                                displayedValue
                                                                                            )
                                                                                        ) : (
                                                                                            <>
                                                                                                {displayedValue ||
                                                                                                    "Не установлено"}
                                                                                                {
                                                                                                    fieldObject.suffix
                                                                                                }
                                                                                            </>
                                                                                        )}
                                                                                    </>
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                    );
                                                                }
                                                            )}
                                                    </div>
                                                </React.Fragment>
                                            )
                                        )}
                                </div>
                            )}

                            {category.link !== "/usdt_price" && <Pagination />}
                        </>
                    }
                </>
            )}
        </div>
    );
};

export default Content;
