class WebSocketManager {
    private socket: WebSocket | null = null;
    private status: string = "disconnected";

    private onOpenCallback: Function | null = null;
    private onCloseCallback: Function | null = null;
    private onErrorCallback: Function | null = null;
    private onMessageCallback: Function | null = null;

    connect(url: string) {
        if (this.socket) {
            this.socket.close();
        }

        this.socket = new WebSocket(url);

        this.socket.onopen = () => this.handleOpen();
        this.socket.onclose = () => this.handleClose();
        this.socket.onerror = (err) => this.handleError(err);
        this.socket.onmessage = (event) => this.handleMessage(event);

        this.updateStatus("connecting");
    }

    private handleOpen() {
        this.updateStatus("connected");
        if (this.onOpenCallback) this.onOpenCallback();
    }

    private handleClose() {
        this.updateStatus("disconnected");
        if (this.onCloseCallback) this.onCloseCallback();
    }

    private handleError(error: any) {
        this.updateStatus("error");
        if (this.onErrorCallback) this.onErrorCallback(error);
    }

    private handleMessage(event: MessageEvent) {
        if (this.onMessageCallback) this.onMessageCallback(event);
    }

    private updateStatus(status: string) {
        this.status = status;
    }

    onOpen(callback: Function) {
        this.onOpenCallback = callback;
    }

    onClose(callback: Function) {
        this.onCloseCallback = callback;
    }

    onError(callback: Function) {
        this.onErrorCallback = callback;
    }

    onMessage(callback: Function) {
        this.onMessageCallback = callback;
    }

    sendMessage(message: string) {
        if (this.socket && this.socket.readyState === WebSocket.OPEN) {
            this.socket.send(message);
        } else {
            console.error("WebSocket is not open.");
        }
    }

    getStatus() {
        return this.status;
    }

    close() {
        if (this.socket) {
            this.socket.close();
        }
    }
}

export const websocketManager = new WebSocketManager();
